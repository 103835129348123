<template>
  <div
    class="container"
    v-bg="{
      remoteSrc: imgData.backgroundImg,
      localSrc: require('@/assets/home-bg.png')
    }"
  >
    <!-- 转盘 -->
    <Turntable
      :initUrl="getTurntableImage"
      :drawUrl="getDrawRecordPre"
      @fetchCb="getFetchData"
      :tracking="{ userVisit: '3' }"
      @drawAfter="drawAfter"
    />
    <!-- 问题咨询/隐私声明 -->
    <helper direction="vertical"></helper>
    <!-- 中奖名单 -->
    <winners></winners>
    <!-- 活动规则 -->
    <activity-rules></activity-rules>
    <!-- 中奖记录按钮 -->
    <assistant @click="onAssistant" v-tracking="{ userVisit: '8' }"></assistant>
    <!-- 中奖记录 -->
    <winningRecord ref="winningRecord"></winningRecord>
  </div>
</template>

<script>
import {
  queryTurntableImagesAndSweepstakesRules,
  drawRecordPre
} from '@/api/index.js'

import { getIsBack } from '@/utils/index.js'
import preventBack from '@/mixins/prevent-browser-back'
import { setSessionStorage, getSessionStorage } from '@/utils/storage.js'
import { qrcodeScanMixin } from '@/mixins/index'

export default {
  name: 'Front',
  mixins: [preventBack, qrcodeScanMixin],
  data() {
    return {
      imgData: {}
    }
  },
  methods: {
    //获取抽奖画面图片和抽奖规则
    getTurntableImage() {
      return queryTurntableImagesAndSweepstakesRules({
        rotaryType: 1
      })
    },
    // 抽奖接口
    getDrawRecordPre() {
      return drawRecordPre()
    },
    getFetchData(data) {
      this.imgData = data
      console.log('getFetchData', data)
    },
    MIXINpreventBackCb() {
      window.location.reload()
    },
    drawAfter(data) {
      let { isTrue } = data.drawResult
      // 中电子奖
      if (isTrue == 0) {
        setSessionStorage('isDrawFrontCoupons', true)
      }
    },
    // 点击助手
    onAssistant() {
      this.$refs.winningRecord.open()
    }
  },
  created() {
    // 二维码首次使用时间
    this.$nextRequest(this.$_getQueryQrcodeScanFirstTime)
  },
  beforeRouteEnter(to, from, next) {
    let backFlag = getIsBack()
    // 如果是通过浏览器(电子奖或实物奖或中置转盘)返回的重定向到后置转盘
    if (
      (backFlag == 'coupons' || from.name == 'Middle') &&
      getSessionStorage('isFirstTime') == false
    ) {
      next({
        name: 'Back'
      })
    } else {
      next()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding-top: 550px;
  height: 2625px;
  background-size: 100% auto;
  background-position: center top;
  background-repeat: no-repeat;
}
</style>
